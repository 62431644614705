<template>
    <KCourse loader-class="MBcont" course-id="8" title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <h3>1. Соединяем тело</h3>
        </div>
                
        <VideoView video-id="08a1ac8237ee4f10b2cfa54ddcbd0f4d"/>

        <div class="MBtextcont">
            <h3>2. Красим волосы</h3>
        </div>

        <VideoView video-id="c00fce158a6e4b6db44c933b8644049d"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Вычесать шкурку</li>
                <li>Помыть, просушить</li>
                <li>Покрасить волосы c шагом в 1.5-2см</li>
            </ol>

            <h3>3. Снимаем выкройку с тела</h3>
        </div>
                
        <VideoView video-id="26defd0751aa4745b32c69905d68a1d1"/>

        <div class="MBtextcont">
            <h3>4. Дорабатываем все детали</h3>
        </div>          

        <VideoView video-id="870715fc100b4887a3377662913cb841"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Убрать всю карандашную наметку</li>
                <li>Отшкурить все детали шкуркой средней зернистости, надфилями</li>
                <li>Отшкурить мелкозернистой шкуркой</li>
                <li>Все углы сгладить кистью</li>
                <li>Отполировать</li>
                <li>Проверить детали на наличие клея, если есть убрать</li>
            </ol>
            <h3 class="nomargin">Итого, что нужно сделать:</h3>
            <ol>
                <li>Соединить тело</li>
                <li>Идеально отшкурить все детали</li>
                <li>Снять выкройку с тела</li>
                <li>Покрасить шкурку</li>
                <li><b>Подготовить материалы</b> к следующему занятию:</li>
                <ol type="a">
                    <li>Грунт, паста акриловая тонкая</li>
                    <li>Маленькие баночки с крышкой до 50мл (от крема, для анализов, из-под суши)</li>
                    <li>Краски акриловые матовые (телесная, белила титановые, охра, синий, красный)</li>
                    <li>Синтетические кисти №6, 4, 2, спонжи</li>
                    <li>Перчатки</li>
                    <li>Мягкая мелкозернистая шкурка (на тканной или вспененной основе)</li>
                </ol>
            </ol>

           <h3>Время выполнения: 5 часов</h3>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js';
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage
        },
        data() {
        return {
            items: chapters
        }
        },
        methods: {
            showImage(src, customClass) {
                this.$swal.fire({
                    customClass: customClass,
                    background: '#ffffff',
                    html: '<img width="100%" src="'+src+'"/>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: "",
                })
            },
        }
    }
</script>

<style>

</style>